export const NAME_SETTINGS = 'settings'
export const PATH_SETTINGS = '/settings'

export default [
  {
    path: PATH_SETTINGS,
    name: NAME_SETTINGS,
    component: () => import('@/views/pages/Settings/SettingGeneral.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
]
