export const NAME_CROP_GRADE_ADD = 'crops_grade_add'
export const PATH_CROP_GRADE_ADD = '/crops/:cropId/grade/add'
export const NAME_CROP_GRADE_EDIT = 'crops_grade_edit'
export const PATH_CROP_GRADE_EDIT = '/crops/:cropId/grade/edit/:id'
export const NAME_CROP_GRADE_ONE = 'crops_grade_one'
export const PATH_CROP_GRADE_ONE = '/crops/:cropId/grade/one/:id'
export default [
  {
    path: PATH_CROP_GRADE_ADD,
    name: NAME_CROP_GRADE_ADD,
    component: () => import('@/views/pages/CropGrades/Add.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
  {
    path: PATH_CROP_GRADE_EDIT,
    name: NAME_CROP_GRADE_EDIT,
    component: () => import('@/views/pages/CropGrades/Edit.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
  {
    path: PATH_CROP_GRADE_ONE,
    name: NAME_CROP_GRADE_ONE,
    component: () => import('@/views/pages/CropGrades/One.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
]
