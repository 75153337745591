import { Preferences } from '@capacitor/preferences'
import moment from '@/libs/moment'

export const LOCALSTORAGE_KEY = 'settings'

export default {
  namespaced: true,
  state: {
    /** @type {Boolean} displayLanguage */
    displayLanguage: true,
  },
  getters: {
    /**
     * @param {Object} state
     * @returns {Boolean}
     */
    getDisplayLanguage: state => state.displayLanguage,
  },
  mutations: {
    /**
     * @param {Object} state
     * @returns {Promise<void>}
     */
    async clear(state) {
      state.displayLanguage = true

      await Preferences.remove({ key: LOCALSTORAGE_KEY })
    },
    /**
     * @param {Object} state
     * @param {Boolean} val
     */
    async setDisplayLanguage(state, val) {
      state.displayLanguage = val

      await Preferences.set({
        key: LOCALSTORAGE_KEY,
        value: JSON.stringify(state),
      })
    },
  },
  actions: {
    /**
     * @param commit
     * @param {Object} state
     * @returns {Promise<void>}
     */
    async restore({
      commit,
      state,
    }) {
      const r = await Preferences.get({ key: LOCALSTORAGE_KEY })

      if (r.value === null) {
        await commit('clear')
        return
      }

      const c = JSON.parse(r.value)
      state.displayLanguage = c.displayLanguage ?? true
    },
  },
}
