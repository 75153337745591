export default {
  /**
   * @param {String} token
   * @param {Number} farmerId
   * @param {String|Number} cropId
   * @param {Object} params
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  list(token, farmerId, cropId, params = {}, headers = {}, cancelToken = null) {
    return {
      url: `/farmers/${farmerId}/crops/${cropId}/packings`,
      method: 'GET',
      headers: {
        Authorization: token,
        ...headers,
      },
      params: {
        ...params,
      },
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
  /**
   * @param {String} token
   * @param {Number} farmerId
   * @param {String|Number} cropId
   * @param {Object} data
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  add(token, farmerId, cropId, data, headers = {}, cancelToken = null) {
    return {
      url: `/farmers/${farmerId}/crops/${cropId}/packings`,
      method: 'POST',
      headers: {
        Authorization: token,
        ...headers,
      },
      data: {
        ...data,
      },
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
  /**
   * @param {String} token
   * @param {Number} farmerId
   * @param {String|Number} cropId
   * @param {String|Number} cropPackingId
   * @param {Object} data
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  edit(token, farmerId, cropId, cropPackingId, data = {}, headers = {}, cancelToken = null) {
    return {
      url: `/farmers/${farmerId}/crops/${cropId}/packings/${cropPackingId}`,
      method: 'PUT',
      headers: {
        Authorization: token,
        ...headers,
      },
      data: {
        ...data,
      },
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
  /**
   * @param {String} token
   * @param {Number} farmerId
   * @param {String|Number} cropId
   * @param {String|Number} cropPackingId
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  get(token, farmerId, cropId, cropPackingId, headers = {}, cancelToken = null) {
    return {
      url: `/farmers/${farmerId}/crops/${cropId}/packings/${cropPackingId}`,
      method: 'PUT',
      headers: {
        Authorization: token,
        ...headers,
      },
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
  /**
   * @param {String} token
   * @param {Number} farmerId
   * @param {String|Number} cropId
   * @param {String|Number} cropPackingId
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  delete(token, farmerId, cropId, cropPackingId, headers = {}, cancelToken = null) {
    return {
      url: `/farmers/${farmerId}/crops/${cropId}/packings/${cropPackingId}`,
      method: 'DELETE',
      headers: {
        Authorization: token,
        ...headers,
      },
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
}
