export const NAME_CROP_TYPE_ADD = 'crops_type_add'
export const PATH_CROP_TYPE_ADD = '/crops/:cropId/type/add'
export const NAME_CROP_TYPE_EDIT = 'crops_type_edit'
export const PATH_CROP_TYPE_EDIT = '/crops/:cropId/type/edit/:id'
export const NAME_CROP_TYPE_ONE = 'crops_type_one'
export const PATH_CROP_TYPE_ONE = '/crops/:cropId/type/one/:id'
export const NAME_CROP_TYPE_ONE_PLOT_ADD = 'crops_type_one_plot_add'
export const PATH_CROP_TYPE_ONE_PLOT_ADD = '/crops/:cropId/type/one/:cropTypeId/plots/add'
export const NAME_CROP_TYPE_ONE_PLOT_ONE = 'crops_type_one_plot_one'
export const PATH_CROP_TYPE_ONE_PLOT_ONE = '/crops/:cropId/type/one/:cropTypeId/plots/one/:plotId'
export default [
  {
    path: PATH_CROP_TYPE_ADD,
    name: NAME_CROP_TYPE_ADD,
    component: () => import('@/views/pages/CropTypes/Add.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
  {
    path: PATH_CROP_TYPE_EDIT,
    name: NAME_CROP_TYPE_EDIT,
    component: () => import('@/views/pages/CropTypes/Edit.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
  {
    path: PATH_CROP_TYPE_ONE,
    name: NAME_CROP_TYPE_ONE,
    component: () => import('@/views/pages/CropTypes/One.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
  {
    path: PATH_CROP_TYPE_ONE_PLOT_ADD,
    name: NAME_CROP_TYPE_ONE_PLOT_ADD,
    component: () => import('@/views/pages/PlotsCropTypes/Add.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
  {
    path: PATH_CROP_TYPE_ONE_PLOT_ONE,
    name: NAME_CROP_TYPE_ONE_PLOT_ONE,
    component: () => import('@/views/pages/PlotsCropTypes/One.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
]
