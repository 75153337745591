import axios, { headersTags } from '@/libs/axios'
import plots from '@/apis/plots'
import moment from '@/libs/moment'
import { collect } from 'collect.js'

/**
 @typedef Plot
 @property {Number} id
 @property {string} name
 @property {string} abbreviation
 @property {Number} parent_id
 @property {Number} farmer_id
 @property {Array<String|Number>} crop_types_id
 @property {Array<String|Number>} crops_id
 @property {Number} user_id
 @property {Array<Plot>|undefined} subplots
 @property {boolean} is_used
 @property {string} created_at
 @property {string} updated_at
 @property {string} deleted_at
 */

export default {
  namespaced: true,
  state: {
    /** @type {null|Array<Plot>} list */
    list: null,
    /** @type {moment} lastPull */
    lastPull: null,
  },
  getters: {
    /**
     * @param state
     * @returns {boolean}
     */
    isFetch(state) {
      return state.list !== null
        && state.lastPull !== null
    },
    /**
     * @param state
     * @returns {number}
     */
    count(state) {
      return state.list !== null ? state.list.length : 0
    },
    /**
     * @param state
     * @returns {boolean}
     */
    has(state) {
      return state.list !== null && state.list.length >= 1
    },
    /**
     * @param state
     * @returns {boolean}
     */
    hasGroup(state) {
      return state.list.filter(e => e.parent_id != null).length >= 1
    },
    /**
     * @param state
     * @returns {Array<Plot>}
     */
    getList(state) {
      if (state.list === null) {
        return []
      }

      return state.list
    },
    /**
     * @param state
     * @returns {Array<Plot>}
     */
    getHierarchicalList(state) {
      if (state.list === null) {
        return []
      }

      return state.list
        .filter(r => r.parent_id === null)
    },
    /**
     * @param state
     * @returns {moment|null}
     */
    getLastPull(state) {
      return state.lastPull
    },
  },
  mutations: {
    clear(state) {
      state.list = null
      state.lastPull = null
    },
    /**
     * @param state
     * @param {Array<Plot>} list
     * @returns {Promise<void>}
     */
    set(state, {
      list,
    }) {
      state.list = list

      const listGroupedByParentId = collect(state.list)
        .groupBy('parent_id')

      /**
       * @param {Plot} plot
       * @returns {Plot}
       */
      const search = plot => {
        // eslint-disable-next-line no-param-reassign
        plot.subplots = listGroupedByParentId.has(plot.id) !== undefined ? listGroupedByParentId.get(plot.id)
          ?.values()
          .all() || [] : []

        // eslint-disable-next-line no-param-reassign
        plot.subplots = plot.subplots.map(search)

        return plot
      }

      state.list = state.list.map(search)
      state.lastPull = moment()
    },
  },
  actions: {
    /**
     * @param commit
     * @param state
     * @param getters
     * @param rootGetters
     * @param {boolean} clearBefore
     * @returns {Promise<boolean>}
     */
    async fetch({
      commit,
      getters,
      rootGetters,
    }, clearBefore = false) {
      const response = await (axios(plots.list(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        {},
        {
          [headersTags.NO_PAGINATE]: true,
          [headersTags.ORDER_BY]: JSON.stringify({
            name: 'asc',
          }),
        },
      )))

      if (clearBefore) commit('clear')
      commit('set', {
        list: response.data?.data,
      })

      return getters.has
    },
    /**
     * @param state
     */
    has({
      state,
    }) {
      if (state.list === null) return null

      const find = state.list
      return (find.length >= 1)
    },
    /**
     * @param state
     * @param id
     * @returns {Plot|null}
     */
    find({
      state,
    }, { id }) {
      if (state.list === null) return null

      const find = state.list.filter(e => e.id === id)
      if (find.length >= 1) return find[0]

      return null
    },
    /**
     * @param rootGetters
     * @param dispatch
     * @param {String} name
     * @param {String} abbreviation
     * @param {Number} parent_id
     * @returns {Promise<boolean>}
     */
    async add({
      rootGetters,
      dispatch,
    }, {
      name,
      abbreviation,
      parent_id,
    }) {
      const response = await (axios(plots.add(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        {
          name,
          abbreviation,
          parent_id,
        },
        {},
      )))

      const result = response.status >= 200 && response.status < 300
      if (result) await dispatch('fetch', true)

      return result
    },
    /**
     * @param rootGetters
     * @param dispatch
     * @param {Number} id
     * @param {String} name
     * @param {String} abbreviation
     * @param {Number} parent_id
     * @returns {Promise<boolean>}
     */
    async edit({
      rootGetters,
      dispatch,
    }, {
      id,
      name,
      abbreviation,
      parent_id,
    }) {
      const response = await (axios(plots.edit(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        id,
        {
          name,
          abbreviation,
          parent_id,
        },
        {},
      )))

      const result = response.status >= 200 && response.status < 300
      if (result) await dispatch('fetch', true)

      return result
    },
    /**
     * @param {Object} getters
     * @param {Number} id
     * @returns {Promise<boolean>}
     */
    async delete({
      rootGetters,
      dispatch,
    }, id) {
      const response = await axios(plots.delete(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        id,
      ))

      const result = response.status >= 200 && response.status < 300
      if (result) await dispatch('fetch', true)

      return result
    },
  },
}
