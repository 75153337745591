import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import auth from './auth'
import farmers from './farmers'
import plots from './plots'
import crops from './crops'
import cropFamilies from './cropFamilies'
import server from './server'
import settings from './settings'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    auth,
    farmers,
    plots,
    crops,
    cropFamilies,
    server,
    settings,
  },
  strict: process.env.DEV,
})
