<template>
  <div
    id="app"
    class="h-100"
  >
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import {
  $themeBreakpoints, $themeColors, $themeConfig, LAYOUT_TYPES,
} from '@appConfig'

import { useCssVar } from '@vueuse/core'
import { Toast } from '@capacitor/toast'

import { App } from '@capacitor/app'
import { NAME_ROOT } from '@/router/routes/general'

const LayoutApp = () => import('@/layouts/app/LayoutApp.vue')
const LayoutBlank = () => import('@/layouts/blank/LayoutBlank.vue')

export default {
  components: {
    // Layouts
    LayoutApp,
    LayoutBlank,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === LAYOUT_TYPES[0]) return 'layout-app'
      if (this.$route.meta.layout === LAYOUT_TYPES[1]) return 'layout-blank'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement)
        .value
        .trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement)
        .value
        .slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  mounted() {
    App.addListener('appStateChange', ({ isActive }) => {
      this.emitter.emit('appStateChange', isActive)
    })

    this.$http.interceptors.response.use(response => response, error => {
      if (error.response.status === 401
          || error.response.status === 408) {
        this.$store.commit('auth/clear')
        this.$store.commit('farmers/clear')
        this.$store.commit('plots/clear')
        this.$store.commit('cropFamilies/clear')
        this.$store.commit('crops/clear')

        Toast.show({
          text: this.$t('therms.session-expired-need-login').toString(),
        })

        this.$router.replace({ name: NAME_ROOT })
      }

      return Promise.reject(error)
    })
  },
}
</script>
