import axios, { headersTags } from '@/libs/axios'
import crops from '@/apis/crops'
import cropTypes from '@/apis/cropTypes'
import cropGrades from '@/apis/cropGrades'
import cropPackings from '@/apis/cropPackings'
import moment from '@/libs/moment'
import collect from 'collect.js'

/**
 @typedef Crop
 @property {Number} id
 @property {string} name
 @property {Number} farmer_id
 @property {Number} crop_family_id
 @property {string} image
 @property {string} emoji
 @property {string} color
 @property {boolean} is_used
 @property {string} created_at
 @property {string} updated_at
 @property {string} deleted_at
 */
/**
 @typedef CropType
 @property {Number} id
 @property {string} name
 @property {Number} crop_id
 @property {string} image
 @property {string} options
 @property {Array<Number|String>} plots_id
 @property {boolean} is_used
 @property {string} created_at
 @property {string} updated_at
 @property {string} deleted_at
 */
/**
 @typedef CropPacking
 @property {Number} id
 @property {string} name
 @property {Number} crop_id
 @property {string} image
 @property {string} weight
 @property {boolean} is_used
 @property {string} created_at
 @property {string} updated_at
 @property {string} deleted_at
 */
/**
 @typedef CropGrade
 @property {Number} id
 @property {string} name
 @property {Number} crop_id
 @property {string} image
 @property {string} weight
 @property {boolean} is_used
 @property {string} created_at
 @property {string} updated_at
 @property {string} deleted_at
 */

export default {
  namespaced: true,
  state: {
    /** @type {null|Array<Crop>} list */
    list: null,
    /** @type {moment} lastPull */
    lastPull: null,
  },
  getters: {
    /**
     * @param state
     * @returns {boolean}
     */
    isFetch(state) {
      return state.list !== null
        && state.lastPull !== null
    },
    /**
     * @param state
     * @returns {number}
     */
    count(state) {
      return state.list !== null ? state.list.length : 0
    },
    /**
     * @param state
     * @returns {boolean}
     */
    has(state) {
      return state.list !== null && state.list.length >= 1
    },
    /**
     * @param state
     * @returns {Array<Crop>}
     */
    getList(state) {
      if (state.list === null) {
        return []
      }

      return state.list
    },
    /**
     * @param state
     * @returns {moment|null}
     */
    getLastPull(state) {
      return state.lastPull
    },
  },
  mutations: {
    clear(state) {
      state.list = null
      state.lastPull = null
    },
    /**
     * @param state
     * @param {Array<Crop>} list
     * @returns {Promise<void>}
     */
    set(state, {
      list,
    }) {
      state.list = list.map(e => {
        e.grades = null
        e.types = null
        e.packings = null

        return e
      })
      state.lastPull = moment()
    },
    /**
     * @param state
     * @param {String|Number} id
     * @param {Array<CropType>} types
     * @returns {Promise<void>}
     */
    setCropTypes(state, {
      id,
      types,
    }) {
      state.list = state.list.map(e => {
        if (e.id === id) {
          e.types = types
        }

        return e
      })
    },
    /**
     * @param state
     * @param {String|Number} id
     * @param {Array<CropGrade>} types
     * @returns {Promise<void>}
     */
    setCropGrades(state, {
      id,
      grades,
    }) {
      state.list = state.list.map(e => {
        if (e.id === id) {
          e.grades = grades
        }

        return e
      })
    },
    /**
     * @param state
     * @param {String|Number} id
     * @param {Array<CropPacking>} types
     * @returns {Promise<void>}
     */
    setCropPackings(state, {
      id,
      packings,
    }) {
      state.list = state.list.map(e => {
        if (e.id === id) {
          e.packings = packings
        }

        return e
      })
    },
  },
  actions: {
    /**
     * @param commit
     * @param state
     * @param getters
     * @param rootGetters
     * @param {boolean} clearBefore
     * @returns {Promise<boolean>}
     */
    async fetch({
      commit,
      getters,
      rootGetters,
    }, clearBefore = false) {
      const response = await (axios(crops.list(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        {},
        {
          [headersTags.NO_PAGINATE]: true,
          [headersTags.ORDER_BY]: JSON.stringify({
            name: 'asc',
          }),
        },
      )))

      if (clearBefore) commit('clear')
      commit('set', {
        list: response.data?.data,
      })

      return getters.has
    },
    /**
     * @param commit
     * @param state
     * @param getters
     * @param rootGetters
     * @param {String|Number} id
     * @param {boolean} force
     * @returns {Promise<void>}
     */
    async loadCropTypes({
      commit,
      state,
      rootGetters,
    }, {
      id,
      force = false,
    }) {
      if (!force) {
        const crop = state.list.filter(e => e.id === id)
        if (crop.length >= 1 && crop[0] !== undefined && crop[0].types !== undefined && crop[0].types !== null) return
      }

      const response = await (axios(cropTypes.list(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        id,
        {},
        {
          [headersTags.NO_PAGINATE]: true,
          [headersTags.ORDER_BY]: JSON.stringify({
            name: 'asc',
          }),
        },
      )))

      commit('setCropTypes', {
        id,
        types: response.data?.data,
      })
    },
    /**
     * @param commit
     * @param state
     * @param getters
     * @param rootGetters
     * @param {String|Number} id
     * @param {boolean} force
     * @returns {Promise<void>}
     */
    async loadCropGrades({
      commit,
      state,
      rootGetters,
    }, {
      id,
      force = false,
    }) {
      if (!force) {
        const crop = state.list.filter(e => e.id === id)
        if (crop.length >= 1 && crop[0] !== undefined && crop[0].grades !== undefined && crop[0].grades !== null) return
      }

      const response = await (axios(cropGrades.list(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        id,
        {},
        {
          [headersTags.NO_PAGINATE]: true,
          [headersTags.ORDER_BY]: JSON.stringify({
            name: 'asc',
          }),
        },
      )))

      commit('setCropGrades', {
        id,
        grades: response.data?.data,
      })
    },
    /**
     * @param commit
     * @param state
     * @param getters
     * @param rootGetters
     * @param {String|Number} id
     * @param {boolean} force
     * @returns {Promise<void>}
     */
    async loadCropPackings({
      commit,
      state,
      rootGetters,
    }, {
      id,
      force = false,
    }) {
      if (!force) {
        const crop = state.list.filter(e => e.id === id)
        if (crop.length >= 1 && crop[0] !== undefined && crop[0].packings !== undefined && crop[0].packings !== null) return
      }

      const response = await (axios(cropPackings.list(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        id,
        {},
        {
          [headersTags.NO_PAGINATE]: true,
          [headersTags.ORDER_BY]: JSON.stringify({
            name: 'asc',
          }),
        },
      )))

      commit('setCropPackings', {
        id,
        packings: response.data?.data,
      })
    },
    /**
     * @param state
     */
    has({
      state,
    }) {
      if (state.list === null) return null

      const find = state.list
      return (find.length >= 1)
    },
    /**
     * @param state
     * @param id
     * @returns {Crop|null}
     */
    find({
      state,
    }, { id }) {
      if (state.list === null) return null

      const find = state.list.filter(e => e.id === id)
      if (find.length >= 1) return find[0]

      return null
    },
    /**
     * @param rootGetters
     * @param dispatch
     * @param {String} name
     * @param {Number} crop_family_id
     * @param {string} image
     * @param {string} emoji
     * @param {string} color
     * @param {Number} parent_id
     * @returns {Promise<boolean>}
     */
    async add({
      rootGetters,
      dispatch,
    }, {
      name,
      crop_family_id,
      image,
      emoji,
      color,
    }) {
      const response = await (axios(crops.add(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        {
          name,
          crop_family_id,
          image,
          emoji,
          color,
        },
        {},
      )))

      const result = response.status >= 200 && response.status < 300
      if (result) await dispatch('fetch', true)

      return result
    },
    /**
     * @param rootGetters
     * @param dispatch
     * @param {String} name
     * @param {Number} cropId
     * @param {string|Number} weight
     * @returns {Promise<boolean>}
     */
    async addCropGrade({
      rootGetters,
      dispatch,
    }, {
      cropId,
      name,
      weight,
    }) {
      const response = await (axios(cropGrades.add(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        cropId,
        {
          name,
          weight,
        },
        {},
      )))

      const result = response.status >= 200 && response.status < 300
      if (result) await dispatch('loadCropGrades', { id: cropId, force: true })

      return result
    },
    /**
     * @param rootGetters
     * @param dispatch
     * @param {String} name
     * @param {Number} cropId
     * @param {Number} cropGradeId
     * @param {string|Number} weight
     * @returns {Promise<boolean>}
     */
    async editCropGrade({
      rootGetters,
      dispatch,
    }, {
      cropId,
      cropGradeId,
      name,
      weight,
    }) {
      const response = await (axios(cropGrades.edit(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        cropId,
        cropGradeId,
        {
          name,
          weight,
        },
        {},
      )))

      const result = response.status >= 200 && response.status < 300
      if (result) await dispatch('loadCropGrades', { id: cropId, force: true })

      return result
    },
    /**
     * @param rootGetters
     * @param dispatch
     * @param {String} name
     * @param {Number} cropId
     * @param {Array<String>} options
     * @returns {Promise<boolean>}
     */
    async addCropType({
      rootGetters,
      dispatch,
    }, {
      cropId,
      name,
      options,
    }) {
      const response = await (axios(cropTypes.add(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        cropId,
        {
          name,
          options,
        },
        {},
      )))

      const result = response.status >= 200 && response.status < 300
      if (result) await dispatch('loadCropTypes', { id: cropId, force: true })

      return result
    },
    /**
     * @param rootGetters
     * @param dispatch
     * @param {String} name
     * @param {Number} cropId
     * @param {Number} cropTypeId
     * @param {Array<String>} options
     * @returns {Promise<boolean>}
     */
    async editCropType({
      rootGetters,
      dispatch,
    }, {
      cropId,
      cropTypeId,
      name,
      options,
    }) {
      const response = await (axios(cropTypes.edit(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        cropId,
        cropTypeId,
        {
          name,
          options,
        },
        {},
      )))

      const result = response.status >= 200 && response.status < 300
      if (result) await dispatch('loadCropTypes', { id: cropId, force: true })

      return result
    },
    /**
     * @param rootGetters
     * @param dispatch
     * @param {String} name
     * @param {Number} cropId
     * @param {string|Number} weight
     * @returns {Promise<boolean>}
     */
    async addCropPacking({
      rootGetters,
      dispatch,
    }, {
      cropId,
      name,
      weight,
    }) {
      const response = await (axios(cropPackings.add(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        cropId,
        {
          name,
          weight,
        },
        {},
      )))

      const result = response.status >= 200 && response.status < 300
      if (result) await dispatch('loadCropPackings', { id: cropId, force: true })

      return result
    },
    /**
     * @param rootGetters
     * @param dispatch
     * @param {String} name
     * @param {Number} cropId
     * @param {Number} cropPackingId
     * @param {string|Number} weight
     * @returns {Promise<boolean>}
     */
    async editCropPacking({
      rootGetters,
      dispatch,
    }, {
      cropId,
      cropPackingId,
      name,
      weight,
    }) {
      const response = await (axios(cropPackings.edit(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        cropId,
        cropPackingId,
        {
          name,
          weight,
        },
        {},
      )))

      const result = response.status >= 200 && response.status < 300
      if (result) await dispatch('loadCropPackings', { id: cropId, force: true })

      return result
    },
    /**
     * @param rootGetters
     * @param dispatch
     * @param {Number} id
     * @param {String} name
     * @param {String} abbreviation
     * @param {Number} parent_id
     * @returns {Promise<boolean>}
     */
    async edit({
      rootGetters,
      dispatch,
    }, {
      id,
      name,
      crop_family_id,
      image,
      emoji,
      color,
    }) {
      const response = await (axios(crops.edit(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        id,
        {
          name,
          crop_family_id,
          image,
          emoji,
          color,
        },
        {},
      )))

      const result = response.status >= 200 && response.status < 300
      if (result) await dispatch('fetch', true)

      return result
    },
    /**
     * @param {Object} getters
     * @param {Number} id
     * @returns {Promise<boolean>}
     */
    async delete({
      rootGetters,
      dispatch,
    }, id) {
      const response = await axios(crops.delete(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        id,
      ))

      const result = response.status >= 200 && response.status < 300
      if (result) await dispatch('fetch', true)

      return result
    },
    /**
     * @param {Object} getters
     * @param {String|Number} cropId
     * @param {String|Number} cropTypeId
     * @returns {Promise<boolean>}
     */
    async deleteCropType({
      rootGetters,
      dispatch,
    }, {
      cropId,
      cropTypeId,
    }) {
      const response = await axios(cropTypes.delete(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        cropId,
        cropTypeId,
      ))

      const result = response.status >= 200 && response.status < 300
      if (result) {
        await dispatch('loadCropTypes', {
          id: cropId,
          force: true,
        })
      }

      return result
    },
    /**
     * @param {Object} getters
     * @param {String|Number} cropId
     * @param {String|Number} cropTypeId
     * @returns {Promise<boolean>}
     */
    async deleteCropPacking({
      rootGetters,
      dispatch,
    }, {
      cropId,
      cropPackingId,
    }) {
      const response = await axios(cropPackings.delete(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        cropId,
        cropPackingId,
      ))

      const result = response.status >= 200 && response.status < 300
      if (result) {
        await dispatch('loadCropPackings', {
          id: cropId,
          force: true,
        })
      }

      return result
    },
    /**
     * @param {Object} getters
     * @param {String|Number} cropId
     * @param {String|Number} cropTypeId
     * @returns {Promise<boolean>}
     */
    async deleteCropGrade({
      rootGetters,
      dispatch,
    }, {
      cropId,
      cropGradeId,
    }) {
      const response = await axios(cropGrades.delete(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        cropId,
        cropGradeId,
      ))

      const result = response.status >= 200 && response.status < 300
      if (result) {
        await dispatch('loadCropGrades', {
          id: cropId,
          force: true,
        })
      }

      return result
    },
    async deletePlotOnCropType({
      state,
      rootGetters,
      dispatch,
    }, {
      cropId,
      cropTypeId,
      plotId,
    }) {
      const [crop] = state.list.filter(c => c.id === cropId)
      if (crop === undefined) throw new Error('Cannot find crop.')

      const [cropType] = crop.types.filter(ct => ct.id === cropTypeId)
      if (cropType === undefined) throw new Error('Cannot find cropType.')

      const plotsIdKept = collect(cropType.plots_id)
        .filter(v => v !== plotId)
        .all()

      const response = await axios(cropTypes.edit(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        crop.id,
        cropType.id,
        {
          ...cropType,
          plots_id: plotsIdKept,
        },
      ))

      const result = response.status >= 200 && response.status < 300
      if (result) {
        await dispatch('loadCropTypes', {
          id: cropId,
          force: true,
        })
        dispatch('plots/fetch', null, { root: true })
          .then()
      }

      return result
    },
    async addPlotOnCropType({
      state,
      rootGetters,
      dispatch,
    }, {
      cropId,
      cropTypeId,
      plotId,
    }) {
      const [crop] = state.list.filter(c => c.id === cropId)
      if (crop === undefined) throw new Error('Cannot find crop.')

      const [cropType] = crop.types.filter(ct => ct.id === cropTypeId)
      if (cropType === undefined) throw new Error('Cannot find cropType.')

      const plotsIdKept = [...cropType.plots_id, plotId]

      const response = await axios(cropTypes.edit(
        rootGetters['auth/getToken'],
        rootGetters['farmers/getDefaultFarmer']?.id,
        crop.id,
        cropType.id,
        {
          ...cropType,
          plots_id: plotsIdKept,
        },
      ))

      const result = response.status >= 200 && response.status < 300
      if (result) {
        await dispatch('loadCropTypes', {
          id: cropId,
          force: true,
        })
        dispatch('plots/fetch', null, { root: true })
          .then()
      }

      return result
    },
  },
}
