import axios, { headersTags } from '@/libs/axios'
import cropFamilies from '@/apis/cropFamilies'
import moment from '@/libs/moment'

/**
 @typedef CropFamily
 @property {Number} id
 @property {string} name
 @property {string} created_at
 @property {string} updated_at
 @property {string} deleted_at
 */

export default {
  namespaced: true,
  state: {
    /** @type {null|Array<CropFamily>} list */
    list: null,
    /** @type {moment} lastPull */
    lastPull: null,
  },
  getters: {
    /**
     * @param state
     * @returns {boolean}
     */
    isFetch(state) {
      return state.list !== null
        && state.lastPull !== null
    },
    /**
     * @param state
     * @returns {number}
     */
    count(state) {
      return state.list !== null ? state.list.length : 0
    },
    /**
     * @param state
     * @returns {boolean}
     */
    has(state) {
      return state.list !== null && state.list.length >= 1
    },
    /**
     * @param state
     * @returns {boolean}
     */
    hasGroup(state) {
      return state.list.filter(e => e.parent_id != null).length >= 1
    },
    /**
     * @param state
     * @returns {Array<CropFamily>}
     */
    getList(state) {
      if (state.list === null) {
        return []
      }

      return state.list
    },
    /**
     * @param state
     * @returns {moment|null}
     */
    getLastPull(state) {
      return state.lastPull
    },
  },
  mutations: {
    clear(state) {
      state.list = null
      state.lastPull = null
    },
    /**
     * @param state
     * @param {Array<CropFamily>} list
     * @returns {Promise<void>}
     */
    set(state, {
      list,
    }) {
      state.list = list
      state.lastPull = moment()
    },
  },
  actions: {
    /**
     * @param commit
     * @param state
     * @param getters
     * @param rootGetters
     * @param {boolean} clearBefore
     * @returns {Promise<boolean>}
     */
    async fetch({
      commit,
      getters,
      rootGetters,
    }, clearBefore = false) {
      const response = await (axios(cropFamilies.list(
        rootGetters['auth/getToken'],
        {},
        {
          [headersTags.NO_PAGINATE]: true,
          [headersTags.ORDER_BY]: JSON.stringify({
            name: 'asc',
          }),
        },
      )))

      if (clearBefore) commit('clear')
      commit('set', {
        list: response.data?.data,
      })

      return getters.has
    },
    /**
     * @param state
     */
    has({
      state,
    }) {
      if (state.list === null) return null

      const find = state.list
      return (find.length >= 1)
    },
    /**
     * @param state
     * @param id
     * @returns {CropFamily|null}
     */
    find({
      state,
    }, { id }) {
      if (state.list === null) return null

      const find = state.list.filter(e => e.id === id)
      if (find.length >= 1) return find[0]

      return null
    },
  },
}
