export const NAME_CROP_PACKING_ADD = 'crops_packing_add'
export const PATH_CROP_PACKING_ADD = '/crops/:cropId/packing/add'
export const NAME_CROP_PACKING_EDIT = 'crops_packing_edit'
export const PATH_CROP_PACKING_EDIT = '/crops/:cropId/packing/edit/:id'
export const NAME_CROP_PACKING_ONE = 'crops_packing_one'
export const PATH_CROP_PACKING_ONE = '/crops/:cropId/packing/one/:id'
export default [
  {
    path: PATH_CROP_PACKING_ADD,
    name: NAME_CROP_PACKING_ADD,
    component: () => import('@/views/pages/CropPackings/Add.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
  {
    path: PATH_CROP_PACKING_EDIT,
    name: NAME_CROP_PACKING_EDIT,
    component: () => import('@/views/pages/CropPackings/Edit.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
  {
    path: PATH_CROP_PACKING_ONE,
    name: NAME_CROP_PACKING_ONE,
    component: () => import('@/views/pages/CropPackings/One.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
]
