import axios, { hasSyncHeader, headersTags } from '@/libs/axios'
import { Preferences } from '@capacitor/preferences'

import moment from '@/libs/moment'
import farmers from '@/apis/farmers'

export const LOCALSTORAGE_KEY = 'farmers'

/**
 @typedef loginResponse
 @property {string} token
 @property {string} device_token
 @property {string} created_at
 @property {string} updated_at
 @property {string} expire_at
 */
/**
 @typedef Farmer
 @property {Number} id
 @property {string} locale
 @property {string} name
 @property {string} created_at
 @property {string} updated_at
 @property {string} deleted_at
 */

export default {
  namespaced: true,
  state: {
    /** @type {null|Array<Farmer>} list */
    list: null,
    /** @type {null|Number} default */
    default: null,
    /** @type {string|null} lastPull */
    lastPull: null,
    /** @type {Boolean} alwaysSelect */
    alwaysSelect: false,
  },
  getters: {
    /**
     * @param state
     * @returns {boolean}
     */
    isFetch(state) {
      return state.list !== null
        && state.lastPull !== null
    },
    /**
     * @param state
     * @returns {boolean}
     */
    isLoad(state) {
      if (state.list === null
        || state.lastPull === null
        || state.default === null) {
        return false
      }

      return state.list.map(d => d.id)
        .includes(state.default)
    },
    /**
     * @param state
     * @returns {Array<Farmer>}
     */
    getList(state) {
      return state.list
    },
    /**
     * @param state
     * @returns {{updated_at: string, name: string, created_at: string, id: Number, locale: string, deleted_at: string}|null}
     */
    getDefaultFarmer(state) {
      return state.list.filter(d => d.id === state.default)[0] ?? null
    },
    /**
     * @param state
     * @returns {boolean}
     */
    hasMultipleFarmers(state) {
      return state.list.length > 1
    },
    /**
     * @param state
     * @returns {boolean}
     */
    getAlwaysSelectChoice(state) {
      return state.alwaysSelect
    },
    /**
     * @param state
     * @returns {moment|null}
     */
    getLastPull(state) {
      return moment(state.lastPull)
    },
  },
  mutations: {
    async clear(state) {
      state.list = null
      state.lastPull = null

      await Preferences.remove({ key: LOCALSTORAGE_KEY })
    },
    /**
     * @param state
     * @param {Array<Farmer>} list
     *
     * @returns {Promise<void>}
     */
    async set(state, list) {
      state.list = list
      state.lastPull = moment()
        .locale('en')
        .format('YYYY-MM-DD HH:mm:ss')
      await Preferences.set({
        key: LOCALSTORAGE_KEY,
        value: JSON.stringify(state),
      })
    },
    /**
     * @param state
     * @param {Array<Farmer>} completeList
     *
     * @returns {Promise<void>}
     */
    async update(state, completeList) {
      state.list = [...completeList, ...state.list.filter(d => !(completeList.map(c => c.id).includes(d.id)))]
      state.lastPull = moment()
        .locale('en')
        .format('YYYY-MM-DD HH:mm:ss')
      await Preferences.set({
        key: LOCALSTORAGE_KEY,
        value: JSON.stringify(state),
      })
    },
    /**
     * @param state
     * @param {Number} id
     */
    async select(state, id) {
      if (state.list.map(d => d.id)
        .includes(id)) {
        state.default = id
      } else {
        state.default = null
      }

      await Preferences.set({
        key: LOCALSTORAGE_KEY,
        value: JSON.stringify(state),
      })
    },
    /**
     * @param state
     * @param {Boolean} change
     */
    async changeAlwaysSelect(state, change) {
      state.alwaysSelect = change

      await Preferences.set({
        key: LOCALSTORAGE_KEY,
        value: JSON.stringify(state),
      })
    },
  },
  actions: {
    /**
     * @param commit
     * @param state
     * @param email
     * @returns {Promise<boolean>}
     */
    async fetch({
      commit,
      getters,
      state,
      rootGetters,
    }) {
      const pullAll = async () => {
        /** @type {AxiosResponse<loginResponse>} response */
        const response = await (axios(await (farmers.list(rootGetters['auth/getToken'], {}, {
          [headersTags.NO_PAGINATE]: true,
        }))))

        commit('clear')
        commit('set', response.data.data)
      }

      const sync = async () => {
        const response = await (axios(await (farmers.list(rootGetters['auth/getToken'], {}, {
          [headersTags.NO_PAGINATE]: true,
          [headersTags.SYNC_DATE]: moment(state.lastPull)
            .locale('en')
            .format('YYYY-MM-DD'),
          [headersTags.SYNC_IDS]: Object.values(state.list)
            .map(data => data.id),
        }))))

        if (!hasSyncHeader(response?.headers)) {
          commit('set', response.data.data)
        } else {
          commit('update', response.data.data)
        }
      }

      if (getters.isFetch) {
        await sync()
      } else {
        await pullAll()
      }

      return true
    },
    /**
     * @param state
     * @param commit
     * @returns {boolean}
     */
    loadFarmer({
      state,
      commit,
    }) {
      if (state.list.length === 1) {
        commit('select', state.list[0].id)
        return true
      }
      return false
    },
    /**
     * @param dispatch
     * @param commit
     * @param state
     * @param rootGetters
     * @returns {Promise<void>}
     */
    async restore({
      dispatch,
      commit,
      state,
      rootGetters,
    }) {
      if (!rootGetters['auth/isLogged']) {
        return
      }
      const r = await Preferences.get({ key: LOCALSTORAGE_KEY })

      if (r.value === null) {
        await commit('clear')
        await dispatch('fetch')
        return
      }

      const c = JSON.parse(r.value)
      state.lastPull = c.lastPull ?? null
      state.list = c.list ?? null
      state.alwaysSelect = c.alwaysSelect ?? false
      state.default = state.alwaysSelect ?? false ? null : c.default ?? null

      await dispatch('fetch')
    },
  },
}
