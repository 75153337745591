import moment from 'moment'
import { extendMoment } from 'moment-range'

const extendedMoment = extendMoment(moment)

extendedMoment.locale(window.navigator.language.split('-')[0])
// eslint-disable-next-line func-names
extendedMoment.fn.toJSON = function () { return this.format() }

export default extendedMoment
