export default {
  /**
   * @param {String} token
   * @param {Object} params
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  list(token, params = {}, headers = {}, cancelToken = null) {
    return {
      url: '/crops/families',
      method: 'GET',
      headers: {
        Authorization: token,
        ...headers,
      },
      params: {
        ...params,
      },
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
  /**
   * @param {String} token
   * @param {String} familyId
   * @param {Object} headers
   * @param {null} cancelToken
   * @returns {{headers: {}, method: string, url: string}}
   */
  get(token, familyId, headers = {}, cancelToken = null) {
    return {
      url: `/crops/families/${familyId}`,
      method: 'PUT',
      headers: {
        Authorization: token,
        ...headers,
      },
      ...(cancelToken !== null ? { cancelToken } : {}),
    }
  },
}
