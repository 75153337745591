/**
 * @param {Number} v
 */
// eslint-disable-next-line func-names
window.numberFormat = function (v) {
  return Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 })
    .format(v)
}

/**
 * Wait time for continue execution
 *
 * @param {Number} ms
 * @returns {Promise<void>}
 */
// eslint-disable-next-line func-names
window.sleep = function (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

/**
 * @returns {boolean}
 */
// eslint-disable-next-line func-names
window.isTablet = function () {
  const userAgent = navigator.userAgent.toLowerCase()
  return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent) || (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform))
}

/**
 * @type {function(): string}
 */
window.randomColor = (() => {
  const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min

  const h = randomInt(0, 360)
  const s = randomInt(60, 98)
  let l = randomInt(40, 80)
  l /= 100
  // eslint-disable-next-line no-mixed-operators
  const a = s * Math.min(l, 1 - l) / 100
  const f = n => {
    const k = (n + h / 30) % 12
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0') // convert to Hex and prefix "0" if needed
  }
  return `#${f(0)}${f(8)}${f(4)}`
})

/**
 *
 * @param color
 * @param percent
 * @returns {*}
 */
window.lightenColor = (color, percent) => {
  const num = parseInt(color, 16)
  const amt = Math.round(2.55 * percent)
  // eslint-disable-next-line no-bitwise
  const R = (num >> 16) + amt
  // eslint-disable-next-line no-bitwise,no-mixed-operators
  const B = (num >> 8 & 0x00FF) + amt
  // eslint-disable-next-line no-bitwise
  const G = (num & 0x0000FF) + amt

  // eslint-disable-next-line no-nested-ternary
  return (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16)
    .slice(1)
}
