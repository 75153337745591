export const NAME_CROP_ALL = 'crops'
export const PATH_CROP_ALL = '/crops'
export const NAME_CROP_ADD = 'crops_add'
export const PATH_CROP_ADD = '/crops/add'
export const NAME_CROP_EDIT = 'crops_edit'
export const PATH_CROP_EDIT = '/crops/edit/:id'
export const NAME_CROP_ONE = 'crops_one'
export const PATH_CROP_ONE = '/crops/one/:id'
export default [
  {
    path: PATH_CROP_ALL,
    name: NAME_CROP_ALL,
    component: () => import('@/views/pages/Crops/All.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
  {
    path: PATH_CROP_ADD,
    name: NAME_CROP_ADD,
    component: () => import('@/views/pages/Crops/Add.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
  {
    path: PATH_CROP_EDIT,
    name: NAME_CROP_EDIT,
    component: () => import('@/views/pages/Crops/Edit.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
  {
    path: PATH_CROP_ONE,
    name: NAME_CROP_ONE,
    component: () => import('@/views/pages/Crops/One.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
]
