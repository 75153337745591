export const NAME_PLOT_ALL = 'plots'
export const PATH_PLOT_ALL = '/plots'
export const NAME_PLOT_ADD = 'plots_add'
export const PATH_PLOT_ADD = '/plots/add'
export const NAME_PLOT_EDIT = 'plots_edit'
export const PATH_PLOT_EDIT = '/plots/edit/:id'
export const NAME_PLOT_ONE = 'plots_one'
export const PATH_PLOT_ONE = '/plots/one/:id'
export default [
  {
    path: PATH_PLOT_ALL,
    name: NAME_PLOT_ALL,
    component: () => import('@/views/pages/Plots/All.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
  {
    path: PATH_PLOT_ADD,
    name: NAME_PLOT_ADD,
    component: () => import('@/views/pages/Plots/Add.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
  {
    path: PATH_PLOT_EDIT,
    name: NAME_PLOT_EDIT,
    component: () => import('@/views/pages/Plots/Edit.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
  {
    path: PATH_PLOT_ONE,
    name: NAME_PLOT_ONE,
    component: () => import('@/views/pages/Plots/One.vue'),
    meta: {
      layout: 'app',
      needUserLogged: true,
    },
  },
]
