import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

// Routes
import general, { NAME_LOADING } from '@/router/routes/general'
import auth, {
  NAME_AUTH, NAME_FORGOT_PASSWORD, NAME_INIT, NAME_LOGIN, NAME_SERVER,
} from '@/router/routes/auth'
import plots from '@/router/routes/plots'
import crops from '@/router/routes/crops'
import cropTypes from '@/router/routes/cropTypes'
import cropGrades from '@/router/routes/cropGrades'
import cropPackings from '@/router/routes/cropPackings'
import farmers, { NAME_FARMERS } from '@/router/routes/farmers'
import settings from '@/router/routes/settings'

import { isLogged } from './utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    ...general,
    ...farmers,
    ...auth,
    ...plots,
    ...crops,
    ...cropTypes,
    ...cropGrades,
    ...cropPackings,
    ...settings,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

const excludedRoute = [
  NAME_LOADING,
  NAME_AUTH,
  NAME_INIT,
  NAME_SERVER,
  NAME_LOGIN,
  NAME_FORGOT_PASSWORD,
  NAME_FARMERS,
]

router.beforeEach(async (to, _, next) => {
  if (to.meta?.layoutType) store.commit('appConfig/UPDATE_LAYOUT_TYPE', to.meta?.layoutType)

  // Redirect if logged in
  if ((to.meta.redirectIfLoggedIn && isLogged()) || (to.meta.needUserLogged && !isLogged())) {
    return next({ name: 'root' })
  }

  if (!excludedRoute.includes(to.name) && !window.isReady) {
    next({ name: 'loading' })
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
